.homepage_layout {
  display: flex;
  flex-direction: column;

  .announcement {
    display: flex;
    flex-direction: column;

    .announcement_box {
      // width: 443px;
      // height: 144px;
      margin: 5px;
      padding: 15px 10px;
      border: 1px solid #8c8c8c;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      display: flex;
      align-items: center;
      flex-direction: row;

      .box_text {
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        span {
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
          color: rgba(0, 0, 0, 0.85);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
        }
      }

      MuiButtonBase-root {
        margin-top: 30px;
      }
    }
  }

  .notifications {
    display: flex;
    flex-direction: column;
    margin: 0px 20px;
    // align-items: center;

    .notification_box {
      border: 0.3px solid #595959;
      background-color: #fafafa;
      display: flex;
      overflow: scroll;
      height: 600px;
      flex-direction: column;
      align-items: center;

      .notification_card {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 15px;

        .notification_content {
          display: flex;
          flex-direction: column;
          margin-left: 20px;
          border-bottom: 1px solid #f1f1f1;

          .upper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0px;
    // display: flex;
    left: 0px;
    right: 0px;

    .footer_content {
      background-color: #ecf6ff;
      padding: 3px 0px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      span {
        margin: 0px 10px;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}

.whiteCard {
  border: 0.5px solid #8c8c8c;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 32px;

  &__header {
    display: flex;
    gap: 20px;
  }

  &__title {
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 28px !important;
    color: rgba(0, 0, 0, 0.85);
  }

  &__text {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 32px !important;
    color: #8c8c8c;
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  }
}

.actionCard {
  border: 0.5px solid #8c8c8c;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  // height: 100%;

  &__header {
    display: flex;
    gap: 20px;
    padding: 25px 32px;
  }

  &__title {
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 28px !important;
    color: rgba(0, 0, 0, 0.85);
  }

  &__text {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 32px !important;
    color: #8c8c8c;
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  }

  &__list {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    span {
      display: block;
    }

    &-title {
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 32px !important;
      color: #000000;
    }

    &-status {
      background: #f0f0f0;
      border-radius: 2px;
      font-weight: 500;
      font-size: 12px;
      line-height: 28px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: #000000;
      min-width: 92px;
    }

    &.active {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      background: rgba(255, 77, 79, 0.2);
    }
  }
}

.massageCard {
  border: 0.5px solid #8c8c8c;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 15%);
  border-radius: 4px;
  padding: 55px;
  text-align: center;

  &__btn {
    width: 100%;
  }

  &__title {
    font-weight: 600 !important;
    font-size: 22px !important;
    line-height: 32px !important;
    color: #595959;
    margin: 18px 0 !important;
  }
}

.ui-breadcrumb {
  margin-top: 16px;

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    list-style-type: none;
    flex-wrap: wrap;
    gap: 7px 0;

    li {
      margin-right: 7px;

      &:first-of-type {
        z-index: 13;
      }

      &:nth-child(2) {
        z-index: 12;
      }

      &:nth-child(3) {
        z-index: 11;
      }

      &:nth-child(4) {
        z-index: 10;
      }

      &:nth-child(5) {
        z-index: 9;
      }

      &:nth-child(6) {
        z-index: 8;
      }

      &:nth-child(7) {
        z-index: 7;
      }

      &:nth-child(8) {
        z-index: 6;
      }

      &:nth-child(9) {
        z-index: 5;
      }

      &:nth-child(10) {
        z-index: 4;
      }

      &:nth-child(11) {
        z-index: 3;
      }

      &:nth-child(12) {
        z-index: 2;
      }

      &:nth-child(13) {
        z-index: 1;
      }
    }
  }

  &__item {
    background: #fff;
    border: 1px solid #d2d6dc;
    font-size: 13px !important;
    color: #ed8154;
    text-transform: capitalize;
    min-width: 28px;
    height: 52px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    text-align: left;
    text-decoration: none;
    box-sizing: border-box;

    &-link {
      position: relative;
      z-index: 9;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px !important;
      color: #ed8154;
      text-decoration: none;
      font-weight: 700;
      height: 44px;
      width: 100%;
      padding-left: 28px;
      padding-right: 2px;

      svg {
        color: #ed8154;

        path {
          fill: #ed8154;
        }
      }

      &::before {
        content: "";
        background: #fff;
        height: 40px;
        width: 40px;
        display: block;
        transform: rotate(45deg);
        position: absolute;
        right: -26px;
        z-index: 0;
        border-right: 1px solid #d2d6dc;
        border-top: 1px solid #d2d6dc;
        border-radius: 8px;
      }

      &::after {
        content: "";
        background: #fff;
        border-right: 1px solid #d2d6dc;
        border-top: 1px solid #d2d6dc;
        height: 40px;
        width: 40px;
        display: block;
        transform: rotate(45deg);
        position: absolute;
        right: -18px;
        border-radius: 8px;
      }

      span {
        position: relative;
        z-index: 1;
      }
    }

    &:last-child {
      .ui-breadcrumb__item-link {
        margin-right: 15px;
      }
    }
    &.activeTab {
      border-color: #7ec4f6;
      background: #e5f5ff;
      .ui-breadcrumb__item-link::after {
        background: #e5f5ff;
        border-color: #7ec4f6;
      }
    }
  }

  li:last-child {
    .ui-breadcrumb__item {
      &-link {
        &::before,
        &::after {
          display: none;
        }
      }
      &.activetab {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        background: rgba(255, 77, 79, 0.2);
      }
    }
  }

  .formLogo {
    width: 30px;
  }
  .jotformLogo {
    width: 20px;
    // justify-content: baseline;
    // align-items: baseline;
  }
}

.smallModel {
  .MuiDialog-paper {
    max-width: 500px;
    width: 100%;
  }

  .MuiDialogContent-root {
    padding: 30px 25px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
    max-width: 600px;
    text-align: center;
  }

  .MuiDialogActions-root {
    position: sticky;
    bottom: 0;
    background: #fff;
    left: 0;
    right: 0;
    width: 90%;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    padding: 12px 20px;
    box-shadow: inset 0px 1px 0px #f0f0f0;

    .border-button {
      padding: 4px 15px;
      height: 32px;
      background: #ffffff !important;
      border: 1px solid #096dd9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      font-family: "Roboto", sans-serif;
      text-align: center;
      color: #096dd9 !important;
    }

    .contained-button {
      padding: 4px 15px;
      height: 32px;
      background: #096dd9 !important;
      border: 1px solid #096dd9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      font-family: "Roboto", sans-serif;
      text-align: center;
      color: #ffffff !important;
    }
  }
}
.StatusPendingTab {
  background-color: #fed239;
  padding: 11px 16px;
  border-radius: 3px;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  max-width: 95px;
  width: 100%;
  text-align: center;
}
.uploadCoustomBtn {
  border-radius: 3px;
}
.ActionStatus {
  font-size: 16px;
  line-height: 30px;
  span {
    font-size: 16px;
    line-height: normal;
  }
  &_box {
    background-color: #cf3e4a;
    border-radius: 2px;
    padding: 2px 8px;
    color: #fff;
    margin-right: 10px;
  }
}
.fileConfirm-dialog {
  display: grid;
  gap: 10px;
}
@media only screen and (max-width: 755px) {
  .whiteCard {
    padding: 15px 30px 15px 15px;
  }
  .actionCard {
    &__list {
      gap: 10px;
      span {
        margin-left: 0 !important;
        font-size: 14px !important;
        line-height: normal;
      }
      .uploadCoustomBtn {
        margin: 0;
        min-width: unset;
        height: auto;
        min-height: auto;
        line-height: 17px;
        padding: 6px 16px;
        border-radius: 3px;
        border: none;
      }
    }
    .StatusPendingTab {
      max-width: fit-content;
      padding: 6px 16px;
      line-height: 17px;
    }
  }
  .ActionStatus {
    font-size: 14px;
    span {
      font-size: 14px;
    }
  }
}
