.top-btn-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;

  div {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 15px;
  }
  .MuiBox-root {
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 8px 24px;
    border: 1px solid #fff;
    position: relative;
    cursor: pointer;
    &::after {
      height: 10px;
      width: 10px;
      border-radius: 20px;
      background-color: #096dd9;
      content: "";
      position: absolute;
      left: 7px;
      top: 14px;
    }
  }
  button {
    height: 42px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .active {
    // color: #096dd9;
    border: 1px solid #096dd9;
  }
}

.commentForm-lavel {
  font-weight: 600;
  margin-bottom: 8px !important;
}
.commentForm {
  position: relative;
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  // border-radius: 8px;
  padding: 15px;
  min-height: 50px !important;
  // height: 26px !important;
  textarea {
    // height: 26px !important;
    min-height: 50px !important;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    border: none;
    background-color: transparent;
    &:focus {
      border: 0;
      outline: 0;
    }
  }
  button {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background: #096dd9 !important;
    border: 1px solid #096dd9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.02) !important;
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #fff !important;
    padding: 4px 15px;
    height: 32px;
    transition: 0.3s;
  }
}

.compose-block {
  display: flex;
  gap: 10px;
  .compose-user-img {
    img {
      width: 30px;
    }
  }
  .compose-user-heading {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 8px 0;
  }
  .compose-user-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0;
  }
  .compose-user-time {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0;
  }
  .compose-user-comment {
    padding: 0;
  }
}
.messageUser {
  margin-bottom: 12px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 8px 24px;
  display: flex;
  align-items: normal;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
}

// .commentListItem {
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-end;
//   margin: 0 0 26px;
//   gap: 8px;
//   .user-info {
//     width: 100%;
//   }
//   .compose-block {
//     display: flex;
//     gap: 10px;
//     img {
//       height: 30px;
//       width: 30px;
//       object-fit: cover;
//     }
//   }
//   .user-img {
//     position: relative;
//     display: flex;
//     align-items: center;
//     img {
//       height: 30px;
//       width: 30px;
//       object-fit: cover;
//     }
//     .active {
//       height: 10px;
//       width: 10px;
//       border-radius: 20px;
//       background-color: #096dd9;
//       margin-right: 10px;
//     }
//   }
// }

.btn-primary {
  background-color: #096dd9;
  color: #fff;
  min-width: 64px;
  padding: 6px 8px;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  margin-bottom: 15px;
  cursor: pointer;
}

.messagecomment-block {
  background-color: transparent !important;
  box-shadow: none !important;
}
.messageInternal-block,
.messagecomment-block {
  padding: 20px;
  margin-bottom: 10px !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  &.active {
    background-color: #ecf6ff;
  }
  .commentListItem__user-info {
    width: 100%;
  }
  .commentListItem {
    margin-bottom: 15px;
  }
  .commentListItem__user-img {
    position: relative;
    display: flex;
    align-items: center;
    img {
      height: 30px;
      width: 30px;
      object-fit: cover;
      border-radius: 50%;
    }
    .active {
      height: 10px;
      width: 10px;
      border-radius: 20px;
      background-color: #096dd9;
      margin-right: 10px;
    }
  }

  .compose-user-name {
    font-weight: normal;
  }
  .compose-user-time {
    margin-left: auto;
    color: #333;
  }
  .messageTicket-number {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    span {
      color: #333;
      font-size: 14px;
      background-color: rgba(255, 255, 255, 0.5);
      padding: 0px 8px;
      box-shadow: 0px 0px 7px 0px #e2e2e2;
    }
  }

  .message-listingBlock {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
// .messageInternal-block.whiteInternam-block {
//   background-color: #fff;
// }

.messageInternal-tabBlockk {
  padding: 12px 20px;
  margin-bottom: 10px !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  color: #000;
  font-weight: 600;
  width: 100%;
  box-sizing: border-box;
  border: none;
  cursor: pointer;
  &.active {
    background: #ecf6ff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  }
  .activemsg {
    height: 10px;
    width: 10px;
    border-radius: 20px;
    background-color: #096dd9;
    margin-right: 10px;
  }
  .visible {
    visibility: hidden;
  }
  &.largeFont {
    font-size: 20px;
  }
}

.listingTable {
  .listingTable__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    button {
      background-color: #096dd9;
      color: #fff;
    }
  }
}
@media only screen and (max-width: 575px) {
  .commentForm {
    button {
      font-size: 12px;
      line-height: normal;
    }
  }
}
