.Modal-block {
  .largeModel__head {
    background: #096dd9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    color: #fff;
    button {
      color: #fff;
      padding: 0;
      min-width: auto;
    }
  }
  .stepperForm__footer {
    padding: 20px;
  }
}

.greenBg {
  background-color: green !important;
}
.yellowBg {
  background-color: #fed239 !important;
  color: black !important;
}
.blueBg {
  background-color: #096dd9 !important;
}
.hidebg{
  visibility: hidden;
}
