.control-section {
  .e-schedule {
    .template-wrap {
      width: 100%;
    }

    //view: day, week
    .e-vertical-view {
      .e-work-cells {
        background: rgb(238, 238, 238) !important;
        &.e-work-hours {
          background: #6bb283 !important;
        }
      }
    }

    //view: timeline day, timeline week
    .e-timeline-view {
      .e-work-cells {
        background: rgb(238, 238, 238) !important;
        &.e-work-hours {
          background: #6bb283 !important;
        }
      }
    }

    //view: month
    .e-month-view {
      .e-work-cells {
        // background: rgb(238, 238, 238) !important;
        &.e-work-hours {
          background: #6bb283 !important;
        }
      }
    }

    // //view: timeline month
    // .e-timeline-month-view {
    //   .e-work-cells {
    //     // background: rgb(238, 238, 238) !important;
    //     &.e-work-hours {
    //       background: #fff; // #096dd9 !important;
    //     }
    //   }
    // }
  }
}
//view: timeline day, timeline week
.e-timeline-view {
  .e-work-cells {
    background: rgb(238, 238, 238) !important;
    &.e-work-hours {
      background: #6bb283 !important;
    }
  }
}

.e-schedule .e-timeline-view .e-work-hours {
  background: #6bb283 !important;
}

.e-schedule .e-vertical-view .e-work-hours {
  background-color: #fff;
  background: #6bb283 !important;
}

.schedule-control-section
  .e-schedule
  .e-timeline-view
  .e-resource-column-wrap
  table
  td
  div {
  width: 100%;
  height: 100%;
}

.meetingPersonBox {
  border-radius: 0;
  margin: 0;
  padding: 5px 10px;
  gap: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.meetingPersonBox img {
  margin: 0;
  border-radius: 50%;
}

.e-schedule .e-timeline-view .e-child-node,
.e-schedule .e-timeline-month-view .e-child-node {
  background-color: rgb(218, 251, 235);
}

.e-quick-popup-wrapper {
  .e-popup-footer {
    .e-event-details {
      display: none !important;
    }
  }
  .e-cell-popup {
    .e-popup-content {
      .e-popup-table {
        .e-schedule-form > span {
          display: none;
        }
      }
    }
  }
}

.e-btn.e-flat.e-primary {
  // display: none !important;
}
