//control schedule popup window
.schedule-detail-model {
  .MuiDialog-paper {
    max-width: 710px;
    padding: 0 40px;
    .largeModel__head {
      // background: #ffffff;
      border-bottom: 1px solid #dee2e6;
      padding: 12px 14px 8px 12px;
      .largeModel__title {
        color: #fff; // #212529;
        font-size: 18px;
        font-weight: 500;
      }
      .largeModel__closeBtn {
        background-color: transparent !important;
        color: #fff; //#212529 !important;
      }
    }
    .largeModel__body {
      .bookingModel {
        padding: 20px;
        .bookingModel__form {
          margin-top: 15px;
          .userList-name {
            span {
              font-size: 15px;
              font-weight: bold;
            }
            .userSmallCircle {
              height: 35px;
              width: 35px;
            }
          }
          .MuiGrid-item {
            padding-top: 2px;
          }
          .fromLabel {
            color: #000;
            font-size: 16px;
            font-weight: bold;
          }
          .fromGroup-chips {
            .MuiPaper-root {
              height: auto;
              font-size: 14px;
              line-height: normal;
            }
          }
          form {
            .MuiGrid-item {
              input {
                height: auto;
                padding: 6.5px 12px;
              }
              button {
                margin-right: 10px;
                line-height: normal;
                padding: 10px 14px;
              }
            }
            .MuiGrid-item.MuiGrid-grid-xs-12 {
              &:first-child {
                padding-top: 0 !important;
                .fromGroup {
                  margin-bottom: 20px;
                }
              }
            }
          }
        }
      }
    }
    .largeModel__footer {
      .button {
        padding: 7px 15px;
        height: auto;
        font-size: 15px;
        line-height: normal;
        font-weight: normal;
      }
      .disabled {
        background-color: rgb(225, 224, 224) !important;
      }
    }
  }
}

.control-section {
  .e-schedule {
    .template-wrap {
      width: 100%;
    }

    //view: day, week
    .e-vertical-view {
      .e-work-cells {
        background: rgb(238, 238, 238) !important;
        &.e-work-hours {
          background: #6bb283 !important;
        }
      }
    }

    //view: timeline day, timeline week
    .e-timeline-view {
      .e-work-cells {
        background: rgb(238, 238, 238) !important;
        &.e-work-hours {
          background: #6bb283 !important;
        }
      }
    }

    //view: month
    .e-month-view {
      .e-work-cells {
        // background: rgb(238, 238, 238) !important;
        &.e-work-hours {
          background: #6bb283 !important;
        }
      }
    }
  }
}

.justify-content-between {
  justify-content: space-between !important;
}

.fromGroup-repeatDay {
  display: flex;
  justify-content: space-between;
  div {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background-color: #eee;
    text-align: center;
    line-height: 30px;
  }
  .fromGroup-dayName {
    background-color: #096dd9;
    color: #fff;
  }
}

.check-AuthBlock {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-weight: 700;
  input {
    margin-right: 16px;
  }
}

.datepickerBlock {
  width: 140px;
  label,
  legend {
    display: none;
  }
  .MuiIconButton-edgeEnd {
    background-color: transparent !important;
    svg {
      color: #096dd9;
      font-size: 20px;
    }
  }
  input {
    border: 0;
    font-size: 14px;
    margin: 0;
  }
  .MuiInputAdornment-positionEnd {
    margin-left: 0 !important;
  }

  .MuiOutlinedInput-notchedOutline {
    top: 0;
  }
}
.deleteSchedule.schedule-detail-model .MuiDialog-paper {
  padding: 0;
}
.checkBoxEdit {
  margin-bottom: 18px;
  label {
    margin-left: 0 !important;
    span {
      color: #000;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.flexNowrap {
  flex-wrap: nowrap !important;
  @media only screen and (max-width: 991px) {
    flex-wrap: wrap !important;
  }
}

.largeModel__head {
  background: #096dd9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  position: sticky;
  top: 0;
  bottom: 10px;
  z-index: 11;
}

.fromGroup-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.fromGroup {
  margin-bottom: 4px;

  .MuiInput-root {
    width: 100%;

    &::after,
    &::before {
      display: none;
    }
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputBase-root {
    width: 100%;

    &.Mui-focused {
      input {
        background: #ecf6ff;
        border: 1px solid #096dd9;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
        border-radius: 2px;
      }
    }
  }

  .MuiSelect-select {
    padding: 8px 12px;
    height: 40px;
    background: #ffffff;
    border: none;
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
  }

  input {
    padding: 8px 12px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #d9d9d9;
  }

  &-chips {
    display: flex;
    flex-wrap: wrap;

    .MuiPaper-root {
      height: 40px;
      background: #fafafa;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 15px;
      box-shadow: none;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      font-family: "Roboto", sans-serif;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
      margin: 4px 0;
    }
  }

  .fromGroup-chip-active {
    color: #096dd9;
    border: 1px solid #096dd9;
  }

  .fromGroup-chip-inactive {
    border: 1px solid #000000;
  }
}

.rebookCard {
  display: "flex";
  width: "100%";
  justify-content: "space-between";
  margin-bottom: "10px";
  &.active {
    background-color: "#ecf6ff";
  }
}

.largeModel {
  padding: 20px;

  .MuiDialog-paper {
    max-width: 660px;
    min-height: 400px;
    width: 100%;
    margin: 0 auto !important;
    border-radius: 8px;
  }
  &__stepperHead {
    display: flex;
    justify-content: center;
    position: sticky;
    background: #fff;
    z-index: 12;
    //padding: 0px 0;
    //margin-bottom: 10px;
  
    .MuiStepper-horizontal {
      justify-content: center;
    }
  
    .MuiStep-root {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 125px;
    }
  
    .MuiStepLabel-iconContainer {
      svg {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #fff;
        border: 2px solid rgba(0, 0, 0, 0.25);
  
        text {
          fill: rgba(0, 0, 0, 0.25) !important;
        }
      }
  
      &.Mui-active {
        svg {
          border: none;
          color: #096dd9;
  
          text {
            fill: #fff !important;
          }
        }
      }
  
      &.Mui-completed {
        svg {
          border: none;
          color: #096dd9;
        }
      }
    }
  
    .MuiStepLabel-label {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      color: rgba(0, 0, 0, 0.25);
  
      &.Mui-active {
        color: #096dd9;
      }
    }
  
    .MuiStepConnector-root {
      top: 22px;
      left: calc(-50% + 22px);
      right: calc(50% + 22px);
      z-index: 0;
  
      .MuiStepConnector-line {
        border-color: #d9d9d9;
        border-top-width: 3px;
      }
    }
  
    .MuiStepLabel-root {
      position: relative;
      z-index: 1;
    }
  }
}

