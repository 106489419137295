.button {
  background: #096dd9 !important;
  border-radius: 5px !important;
  min-height: 48px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 32px !important;
  gap: 10px;
  display: flex !important;
  align-items: center !important;
  text-align: center;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.link {
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 24px !important;
  text-decoration-line: underline !important;
  color: #096dd9 !important;
  background: transparent !important;
  border-radius: 0 !important;
  border: none !important;
  text-transform: capitalize !important;
  padding: 0 !important;
}

.innerTabs {
  padding: 0;
  margin: 32px 0 15px;
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  // overflow-x: auto;
  // height: 40px;

  &__item {
    button {
      font-weight: 400;
      font-size: 20px;
      line-height: 34px;
      display: flex;
      align-items: center;
      text-align: center;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
      position: relative;
      background: transparent;
      border: none;
      box-shadow: none;

      &::after {
        content: "";
        width: 100%;
        height: 3px;
        border-radius: 10px;
        background-color: #096dd9;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        opacity: 0;
      }

      &.active {
        color: #096dd9;
        font-weight: 700;

        &::after {
          opacity: 100%;
        }
      }
    }
  }
}
@media only screen and (max-width: 575px) {
  .innerTabs {
    gap: 10px;
    &__item {
      button {
        font-size: 16px;
      }
    }
  }
}
