.massageTemplate {
    display: grid;
    grid-template-columns: 330px auto;
    gap: 22px;
    padding: 0 32px;

    &__page {
        padding: 45px 0;
    }
}

.sidebar {
    background: #F5F5F5;
    border: 1px solid #D9D9D9;.massageTemplate {
    display: grid;
    grid-template-columns: 330px auto;
    gap: 22px;
    padding: 0 32px;

    &__page {
        padding: 45px 0;
    }
}

.sidebar {
    background: #F5F5F5;
    border: 1px solid #D9D9D9;
    border-radius: 0px 0px 4px 4px;
    padding: 40px 0;

    &__head {
        padding: 20px 24px;

        &-title {
            font-weight: 500 !important;
            font-size: 30px !important;
            line-height: 40px !important;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 0 0 7px;
        }

        &-subText {
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #096DD9;
            gap: 7px;
        }

        .button {
            margin-top: 30px;
            width: 100%;
            padding: 1px 22px;
            min-height: 35px !important;
            box-sizing: border-box;
            font-weight: 400 !important;
        }
    }

    &__tab {
        .MuiTabs-root {
            padding-left: 24px;

            .MuiTabs-indicator {
                display: none;
            }

            .MuiTabs-flexContainer {
                flex-direction: column;
                gap: 5px;

                button {
                    padding: 5px 20px;
                    min-height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: 1px solid transparent;
                    border-left: 3px solid transparent;
                    background: transparent !important;
                    border-right: none;
                    text-transform: capitalize;
                    flex-direction: row;

                    .MuiTypography-root {
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 28px;
                        color: #595959 !important;
                        gap: 18px;
                        display: flex;
                        align-items: center;
                    }

                    svg path {
                        fill: #595959;
                    }

                    span {
                        width: 20px;
                        height: 20px;
                        background: #1890FF;
                        border-radius: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                    }

                    .MuiTouchRipple-root {
                        display: none;
                    }

                    &.Mui-selected {
                        border-color: #BFBFBF;
                        border-left-color: #096DD9;

                        .MuiTypography-root {
                            color: #096DD9 !important;
                        }

                        svg path {
                            fill: #096DD9;
                        }
                    }
                }
            }
        }
    }
}

.notificationBlock {
    background: #FAFAFA;
}

.notificationCard {
    display: flex;
    gap: 0;

    &__info {
        padding: 20px 20px;
        border-bottom: 1px solid #D9D9D9;
        padding-left: 0;
        margin-left: 10px;
    }

    &__head {
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }

    &__title {
        font-weight: 700 !important;
        font-size: 26px !important;
        line-height: 32px !important;
        color: rgba(0, 0, 0, 0.85);
    }

    &__subTitle {
        font-weight: 600 !important;
        font-size: 24px !important;
        line-height: 32px !important;
        color: rgba(0, 0, 0, 0.85);
        margin: 5px 0 0 !important;
    }

    &__date {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: rgba(0, 0, 0, 0.85);
    }

    &__img {
        padding: 25px 20px;

        img {
            border-radius: 100px;
            height: 58px;
            width: 58px;
            overflow: hidden;
        }
    }

    &__text {
        font-weight: 400;
        font-size: 18px;
        line-height: 131%;
        color: #333333;
        margin: 5px 0;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: -18px;
            top: 7px;
            height: 10px;
            width: 10px;
            background: #0050B3;
            display: block;
            border-radius: 50%;
        }
    }

    &:last-child {
        .notificationCard__info {
            border-bottom: none;
        }
    }
}

.notificationsPage {
    &__head {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
    }

    &__title {
        font-weight: 400 !important;
        font-size: 24px !important;
        line-height: 32px !important;
        color: rgba(0, 0, 0, 0.85);
    }

    &__subText {
        font-weight: 300 !important;
        font-size: 14px !important;
        line-height: 16px !important;
        color: #595959;
    }
}
    border-radius: 0px 0px 4px 4px;
    padding: 40px 0;

    &__head {
        padding: 20px 24px;

        &-title {
            font-weight: 500 !important;
            font-size: 30px !important;
            line-height: 40px !important;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 0 0 7px;
        }

        &-subText {
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #096DD9;
            gap: 7px;
        }

        .button {
            margin-top: 30px;
            width: 100%;
            padding: 1px 22px;
            min-height: 35px !important;
            box-sizing: border-box;
            font-weight: 400 !important;
        }
    }

    &__tab {
        .MuiTabs-root {
            padding-left: 24px;

            .MuiTabs-indicator {
                display: none;
            }

            .MuiTabs-flexContainer {
                flex-direction: column;
                gap: 5px;

                button {
                    padding: 5px 20px;
                    min-height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: 1px solid transparent;
                    border-left: 3px solid transparent;
                    background: transparent !important;
                    border-right: none;
                    text-transform: capitalize;
                    flex-direction: row;

                    .MuiTypography-root {
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 28px;
                        color: #595959 !important;
                        gap: 18px;
                        display: flex;
                        align-items: center;
                    }

                    svg path {
                        fill: #595959;
                    }

                    span {
                        width: 20px;
                        height: 20px;
                        background: #1890FF;
                        border-radius: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                    }

                    .MuiTouchRipple-root {
                        display: none;
                    }

                    &.Mui-selected {
                        border-color: #BFBFBF;
                        border-left-color: #096DD9;

                        .MuiTypography-root {
                            color: #096DD9 !important;
                        }

                        svg path {
                            fill: #096DD9;
                        }
                    }
                }
            }
        }
    }
}

.notificationBlock {
    background: #FAFAFA;
}

.notificationCard {
    display: flex;
    gap: 0;

    &__info {
        padding: 20px 20px;
        border-bottom: 1px solid #D9D9D9;
        padding-left: 0;
        margin-left: 10px;
    }

    &__head {
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }

    &__title {
        font-weight: 700 !important;
        font-size: 26px !important;
        line-height: 32px !important;
        color: rgba(0, 0, 0, 0.85);
    }

    &__subTitle {
        font-weight: 600 !important;
        font-size: 24px !important;
        line-height: 32px !important;
        color: rgba(0, 0, 0, 0.85);
        margin: 5px 0 0 !important;
    }

    &__date {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: rgba(0, 0, 0, 0.85);
    }

    &__img {
        padding: 25px 20px;

        img {
            border-radius: 100px;
            height: 58px;
            width: 58px;
            overflow: hidden;
        }
    }

    &__text {
        font-weight: 400;
        font-size: 18px;
        line-height: 131%;
        color: #333333;
        margin: 5px 0;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: -18px;
            top: 7px;
            height: 10px;
            width: 10px;
            background: #0050B3;
            display: block;
            border-radius: 50%;
        }
    }

    &:last-child {
        .notificationCard__info {
            border-bottom: none;
        }
    }
}

.notificationsPage {
    &__head {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
    }

    &__title {
        font-weight: 400 !important;
        font-size: 24px !important;
        line-height: 32px !important;
        color: rgba(0, 0, 0, 0.85);
    }

    &__subText {
        font-weight: 300 !important;
        font-size: 14px !important;
        line-height: 16px !important;
        color: #595959;
    }
}