.stepperForm__inner {
  padding: 20px 20px 0px 20px;
  .fromGroup-chips {
    display: flex;
    gap: 20px;
    .MuiPaper-root {
      height: 40px;
      background: #fafafa;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      box-shadow: none;
      font-family: "Roboto", sans-serif;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
    }
    .active {
      border: 1px solid #096dd9 !important;
      color: #096dd9 !important;
    }
  }
}

.fromGroup {
  .MuiTextField-root {
    width: 100%;
  }
  input {
    padding: 8px 12px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
  }
}

.stepperForm__footer {
  button {
    font-weight: 400 !important;
    min-height: 32px !important;
    line-height: normal !important;
    margin-left: auto;
  }
}

.Modal-block {
  .MuiDialog-paper {
    max-width: 900px;
  }
}

.commentField textarea {
  padding: 8px 12px;
  background: rgb(255, 255, 255);
  border: 1px solid #0000003b;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.selectedIn-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  margin-left: 12px;

  h1 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0;
    line-break: anywhere;
  }

  span {
    cursor: pointer;

    svg {
      color: #096dd9;
      height: 20px;
      width: 20px;
    }
  }
}