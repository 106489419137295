.search-block {
  max-width: 325px;
  height: 32px;
  width: 300px;

  &__icon {
    padding: 0 12px;
  }

  .MuiInputBase-root {
    width: 100%;
  }

  input {
    height: 32px;
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    background-color: transparent;
    border-radius: 8px;
    font-size: 16px;
    padding-left: 40px;

    &::placeholder {
      color: #000000;
      opacity: 1;
    }
  }
}

.rdrCalendarWrapper {
  color: #000000;
  font-size: 12px;
  max-width: 450px;
  width: 100%;
  position: absolute;
  right: 0;
  z-index: 11;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 15%);

  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrMonthAndYearWrapper {
    background: #096dd9;
    padding: 0;
    height: 40px;

    select {
      color: #fff;

      option {
        color: #000;
      }
    }

    .rdrMonthPicker,
    .rdrYearPicker {
      position: relative;

      &:after {
        content: "";
        height: 8px;
        width: 8px;
        display: block;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        position: absolute;
        right: 6px;
        top: 12px;
        transform: rotate(-45deg);
      }
    }
  }
}

.mainHeader {
  padding: 8px 25px;
  min-height: 64px;

  &__toggle {
    margin: 0;
    margin-right: 24px;
    padding: 0 !important;
    display: none !important;
    background: #fff !important;
    color: #096dd9 !important;

    &:hover {
      background: transparent;
    }
  }

  &__logo {
    height: 64px;
    width: auto;
  }

  .search-block {
    max-width: 325px;
    height: 32px;
    width: 300px;

    &__icon {
      padding: 0 12px;
    }

    .MuiInputBase-root {
      width: 100%;
    }

    input {
      height: 32px;
      box-sizing: border-box;
      border: 1px solid #d9d9d9;
      background-color: transparent;
      border-radius: 8px;
      font-size: 16px;
      padding-left: 40px;

      &::placeholder {
        color: #000000;
        opacity: 1;
      }
    }
  }

  &__menu {
    margin: 0 65px;
  }

  &__mobileToggle {
    display: none;
    margin: 0;
    padding: 0;
    margin-left: 12px;
    align-items: center;
    justify-content: center;
  }
}


.userBox {
  display: flex;
  align-items: center;
  margin-right: 22px;

  &__image {
    height: 34px;
    width: 34px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__name {
    font-size: 18px;
    color: #000000;
    line-height: 18px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
  }
}

.logout-icon {
  height: 35px;
  width: 35px;
  border-radius: 10px !important;
  overflow: hidden;
  background-color: #1976d2 !important;
  padding: 8px;
  fill: #fff;
  color: #fff !important;
}
.overlay{
  opacity: 0;
  display: none;
}
.mobile-controls{
  display: none !important;
}


@media only screen and (max-width: 1400px) {
  .mainHeader {
    &__menu {
      margin: 0px 28px;
    }

    &__logo {
      height: 42px;
    }

    .search-block {
      width: 225px;
    }
  }

  .userBox__name {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  .mainHeader {
    &__menu {
      margin: 0px 20px;
    }

    &__toggle {
      margin-right: 16px;
    }

    &__logo {
      height: 38px;
    }

    .search-block {
      width: 190px;
    }
  }

  .userBox__name {
    font-size: 14px;
  }

  .userBox__image {
    height: 30px;
    width: 30px;
    margin-right: 8px;
  }
}

@media only screen and (max-width: 1024px) {
  .logout-icon {
    height: 30px;
    width: 30px;
  }

  .mainHeader__menu {
    margin: 0px 10px;
  }
}

@media only screen and (max-width: 991px) {
  .mainHeader {
    padding: 8px 22px;

    &__toggle {
      display: flex !important;
    }

    &__logo {
      height: 35px;
    }

    &__menu {
      margin: 0;
      padding: 20px 0;
      border-top: 1px solid #eee;

      .menuItem {
        padding: 8px 20px;
        // min-height: 49px;
        margin: 0;

        &__link {
          font-family: "Roboto", sans-serif;
          font-weight: 500;
          font-size: 17px;
          line-height: 25px;
          color: rgba(0, 0, 0, 0.85);
        }

        &:hover {
          background-color: #ecf6ff !important;

          .menuItem__link {
            color: #1976d2;
          }
        }
      }
    }

   

    .search-block {
      width: 32px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      position: inherit;
      position: absolute;
      top: calc(100% + 1px);
      width: 100%;
      right: 0;
      border-bottom: 1px solid #ddd;
      background: #fff;
      width: 100%;
      max-width: 100%;
      display: none;

      &__icon {
        padding: 0;
        right: 0;
      }

      & > .MuiInputBase-root {
        // position: absolute;
        // left: 0;
        // right: 0;
        // top: calc(100% + 1px);
        // z-index: 15;
        // height: 42px;
        // border-bottom: 3px solid #eee;
        // background: #fff;

        input {
          border: none;
          padding: 8px 26px;
        }
      }
      &.active-search {
        display: flex;
      }
    }
  }

  .userBox {
    margin-right: 12px;

    &__name {
      display: none;
    }

    &__image {
      margin-right: 0;
    }
  }

  
  .desk-menu{
    position: fixed;
    top:0;
    left: -300px;
    width: 300px;
    height: 100vh;
    z-index: 1050;
    transition: 0.5s ease all;
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }

  .openMobileMenu {
    .desk-menu.open {
      left: 0px;
    }
    .overlay{
      display: block;
      opacity: 1;
      position: fixed;
      inset: 0;
      background: rgb(0 0 0 / 25%);
      z-index: 99;
    }
    .mobile-controls{
      display: flex !important;
    }
  }
}

.SideLink {
  color: black;
  text-decoration: none;
}

.mobileSearch {
  display: none;
  @media only screen and (max-width: 991px) {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
}

#demo-simple-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 4px;
}