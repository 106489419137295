.pageTemplate {
  padding: 32px;
  height: calc(100vh - 145px);
  overflow: auto;
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 25px;
  }

  &__title {
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 32px !important;
    color: rgba(0, 0, 0, 0.85);
    text-transform: capitalize;
  }
}

.uploadBlock {
  margin-top: 20px;
  margin-bottom: 50px;

  &__title {
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 32px !important;
    color: rgba(0, 0, 0, 0.85);
  }

  .uploadCard {
    height: 176px;
    margin-top: 25px;
    background-color: #f5f5f5;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fileListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  &__heading {
    // display: flex;
    // align-items: center;
    // gap: 10px;
    display: grid;
    align-items: center;
    gap: 10px;
    grid-template-columns: 28px auto;
    svg {
      height: 28px;
      width: 28px;
    }
  }

  &__name {
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 30px !important;
    color: #fa8c16;
  }

  &__status {
    background: #fafafa !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 2px !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 18px !important;
    color: rgba(0, 0, 0, 0.85) !important;
    padding: 3px 16px !important;
    min-height: 0;
    height: auto;
    text-transform: capitalize !important;

    &--done {
      background: #002766 !important;
      border: 1px solid #002766 !important;
      color: #fff !important;
    }
  }
}

.uploadCoustomBtn {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  // background: #ffffff;
  background-color: #096dd9;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgb(0 0 0 / 2%);
  border-radius: 2px;
  padding: 5px 15px;
  gap: 10px;
  color: #fff;
  // margin-left: 20px;

  svg path {
    fill: #fff;
  }

  input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .btn {
    width: 104px;
    height: 104px;
    background: #096dd9;
    border: 1px dashed #ffffff;
    border-radius: 2px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
      height: 16px;
      width: 16px;
      margin-bottom: 5px;
    }
  }

  & > .MuiBox-root {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    width: 100%;
    opacity: 0%;
    button {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }
}

.fileCardsModule {
  display: flex;
  gap: 80px;
  margin-top: 20px;
}

.formCard {
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  background: #ffffff;
  border: 0.5px solid #8c8c8c;
  width: 285px;
  cursor: pointer;
  &__img {
    height: 260px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__btn {
    background: #f0f0f0;
    border: none;
    padding: 18px;
    font-weight: 500;
    font-size: 15px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #434343;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  &__moreBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    width: 18px;
    cursor: pointer;
  }
}

.documentCard {
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  background: #ffffff;
  border: 0.5px solid #c3bebe;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  // overflow: hidden;
  &__img {
    background: #f0f0f0;
    height: 155px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 30px 0;
    // overflow: hidden;
    box-sizing: border-box;
    border-radius: 5px 5px 0 0;
    &-card {
      filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
      background: #ffffff;
      border-radius: 5px;
      text-align: left;
      padding: 12px;
      background: #ffffff;
      border-radius: 5px 5px 0 0;
      text-align: left;
      padding: 25px;
      height: 100%;
      box-sizing: border-box;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }
  }
  &__btn {
    background: #fff;
    border: none;
    padding: 12px 18px;
    font-weight: 500;
    font-size: 15px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #434343;
    justify-content: space-between;
    border-top: 1px solid #c3bebe;
    border-radius: 0 0 5px 5px;
    position: relative;
    img {
      width: 35px;
      height: 35px;
      object-fit: contain;
    }
    span {
      padding: 8px 20px;
      border-radius: 5px;
      background-color: #096dd9;
      color: #fff;
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.TableBlock.filesTable {
  margin-bottom: 50px;

  .MuiTableContainer-root {
    padding: 5px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 20px;
    .fileListItem__name {
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 32px !important;
      // display: flex;
      // align-items: center;
      color: rgba(0, 0, 0, 0.85);
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  thead tr {
    background: #f5f5f5;
    border: 0.5px solid #8c8c8c;
    box-shadow: 0px 0px 2px #8c8c8c;

    th {
      border-bottom: none;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      color: #096dd9;
      padding: 14px;
      white-space: nowrap;
      width: 50%;
      &:last-child {
        // width: 300px;
        text-align: right;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #d9d9d9;
      background-color: #fff;
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 4px;

      &:nth-child(odd) {
        background: #ecf6ff;
      }

      td {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: rgba(0, 0, 0, 0.85);
        white-space: nowrap;
        width: 50%;

        &:last-child {
          // width: 300px;
          text-align: right;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .pageTemplate {
    padding: 22px;
  }

  .dashboard {
    padding: 32px 22px;
  }

  .TableBlock {
    thead tr th {
      font-size: 18px;
      line-height: 25px;
      padding: 10px 14px;
    }

    tbody tr td {
      font-size: 16px;
      line-height: 28px;
      padding: 14px;
    }
  }

  .fileListItem {
    &__name {
      font-size: 16px !important;
      line-height: 28px !important;
    }

    &__heading {
      grid-template-columns: 18px auto;
      gap: 5px;
      svg {
        height: 16px;
        width: 16px;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .uploadBlock {
    margin-bottom: 30px;
  }
  .TableBlock {
    &.filesTable {
      table {
      .fileListItem {
        &__name {
          font-size: 14px !important;
        }
      }
      thead {
        tr {
          th {
            font-size: 14px;
            padding: 5px;
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 14px;
            padding: 5px;
          }
        }
      }
    }
    }
  }
}
